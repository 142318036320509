"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var styles = {
  container: 'container',
  row: 'row'
};
var _default = exports["default"] = styles;
module.exports = exports.default;