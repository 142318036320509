"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = cls;
var _clsx = _interopRequireDefault(require("clsx"));
var _tailwindMerge = require("tailwind-merge");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var validatorCustomClass = function validatorCustomClass(value) {
  return /\b[bhc]\d{1,4}\b/g.test(value);
};
var twMerge = (0, _tailwindMerge.extendTailwindMerge)({
  extend: {
    classGroups: {
      'font-size': [{
        text: [validatorCustomClass]
      }]
    }
  }
});
function cls() {
  var className = _clsx["default"].apply(void 0, arguments);
  return twMerge(className);
}
module.exports = exports.default;