"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withPrefix = exports.getParentPath = void 0;
var isLocalLink = function isLocalLink(path) {
  return Boolean(path && !path.startsWith("http://") && !path.startsWith("https://") && !path.startsWith("//") && !path.startsWith('tel:') && !path.startsWith('mailto:') && !path.startsWith("#"));
};
var withPrefix = exports.withPrefix = function withPrefix(href, prefix) {
  var _prefixedHref$split, _prefixedHref$split2, _prefixedHref;
  var trailingSlash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!href) {
    return '#';
  }
  if (!isLocalLink(href)) {
    return href;
  }
  if (href.startsWith("./") || href.startsWith("../")) {
    return href;
  }
  var base = prefix !== null && prefix !== void 0 ? prefix : "/";
  var prefixedHref = "".concat(base !== null && base !== void 0 && base.endsWith("/") ? base.slice(0, -1) : base).concat(href.startsWith("/") ? href : "/".concat(href));
  var params = prefixedHref.indexOf('?') !== -1 ? (_prefixedHref$split = prefixedHref.split('?')) === null || _prefixedHref$split === void 0 ? void 0 : _prefixedHref$split[1] : null;
  prefixedHref = params ? (_prefixedHref$split2 = prefixedHref.split('?')) === null || _prefixedHref$split2 === void 0 ? void 0 : _prefixedHref$split2[0] : prefixedHref;
  if (href.indexOf('#') !== -1) {
    return prefixedHref;
  }
  if (!((_prefixedHref = prefixedHref) !== null && _prefixedHref !== void 0 && _prefixedHref.endsWith("/")) && trailingSlash) {
    prefixedHref = "".concat(prefixedHref, "/");
  }
  if (params) {
    prefixedHref = prefixedHref.concat("?".concat(params));
  }
  return prefixedHref;
};
var getParentPath = exports.getParentPath = function getParentPath(path) {
  if (path === '/') return '';
  var withFinalSlash = path.endsWith('/');
  var parent = withFinalSlash ? path.slice(0, -1) : path;
  parent = parent.split('/').slice(0, -1).join('/');
  parent += withFinalSlash ? '/' : '';
  return parent;
};