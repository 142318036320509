"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var baseStyles = {
  btn: 'items-center inline-flex justify-center border-none relative transition-all',
  'full-width': 'w-full',
  input: 'hidden'
};
var dynamicStyles = {
  get: function get(target, prop) {
    if (prop.startsWith('variant-') || prop.startsWith('size-')) {
      return prop;
    }
    return target[prop] || '';
  }
};
var styles = new Proxy(baseStyles, dynamicStyles);
var _default = exports["default"] = styles;
module.exports = exports.default;