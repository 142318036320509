"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var baseStyles = {
  root: 'message flex items-center',
  icon: 'flex-[0_0_1rem]',
  text: 'flex-1'
};
var dynamicStyles = {
  get: function get(target, prop) {
    var _target$prop;
    if (prop.startsWith('variant-') || prop.startsWith('size-')) {
      return prop;
    }
    return (_target$prop = target[prop]) !== null && _target$prop !== void 0 ? _target$prop : '';
  }
};
var styles = new Proxy(baseStyles, dynamicStyles);
var _default = exports["default"] = styles;
module.exports = exports.default;