"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _plugin = _interopRequireDefault(require("tailwindcss/plugin.js"));
var _lodash = _interopRequireDefault(require("lodash.foreach"));
var _lodash2 = _interopRequireDefault(require("lodash.reduce"));
var _lodash3 = _interopRequireDefault(require("lodash.kebabcase"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var typographyPlugin = function typographyPlugin(_ref) {
  var prefix = _ref.prefix,
    _ref$typography = _ref.typography,
    typography = _ref$typography === void 0 ? {} : _ref$typography;
  return (0, _plugin["default"])(function (_ref2) {
    var addBase = _ref2.addBase,
      addUtilities = _ref2.addUtilities,
      theme = _ref2.theme;
    var fontsGroups = typography;
    var fontPrefix = prefix ? "--".concat(prefix, "-font") : '--font';
    var breakpoints = _objectSpread({
      xs: '0px'
    }, theme('screens'));
    var rootStyles = {
      ':root': {}
    };
    var fontStyles = {};
    var firstBp = Object.keys(breakpoints).at(0);

    //  Create root styles for each breakpoint
    Object.keys(breakpoints).forEach(function (bp) {
      if (bp !== firstBp) {
        rootStyles["@screen ".concat(bp)] = {
          ':root': {}
        };
      }
    });
    var getValue = function getValue(value) {
      return typeof value === 'number' ? "".concat(value / 16, "rem") : value;
    };
    var getFontPropsByArray = function getFontPropsByArray(prop) {
      return (0, _lodash2["default"])(Object.entries(prop), function (acc, _ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          key = _ref4[0],
          value = _ref4[1];
        var parsedValue = getValue(value);
        if (typeof parsedValue === 'number') {
          parsedValue = "".concat(parsedValue, "rem");
        }
        acc[key] = parsedValue;
        return acc;
      }, {});
    };

    // Function to parse font properties to css rule object
    var getFontProps = function getFontProps(fontProps) {
      if (typeof fontProps === 'string' || typeof fontProps === 'number') {
        return {
          fontSize: getValue(fontProps)
        };
      }
      if (Array.isArray(fontProps)) {
        return (0, _lodash2["default"])(fontProps, function (acc, prop, i) {
          if (i === 0) {
            return _objectSpread(_objectSpread({}, acc), {}, {
              fontSize: getValue(prop)
            });
          }
          if (i === 1) {
            // Support [fontSize, lineHeight] format
            if (typeof prop === 'string' || typeof prop === 'number') {
              return _objectSpread(_objectSpread({}, acc), {}, {
                lineHeight: getValue(prop)
              });
            }

            // Support [fontSize, { lineHeight?, letterSpacing?, fontWeight? }] format
            if (_typeof(prop) === 'object') {
              return _objectSpread(_objectSpread({}, acc), getFontPropsByArray(prop));
            }
          }
          return acc;
        }, {});
      }
      var parsedProps = getFontPropsByArray(fontProps);
      return parsedProps;
    };

    // Function to generate utility classes
    var generateFontClasses = function generateFontClasses(props, prefix, name) {
      var className = ".text-".concat(name);
      fontStyles[className] = fontStyles[className] || {};
      (0, _lodash["default"])(Object.entries(props), function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
          key = _ref6[0],
          value = _ref6[1];
        // skip if value is a css variable
        if (/var\(([a-z-0-9,\s]+)\)/.test(value)) {
          fontStyles[className][key] = value;
          return;
        }
        var varName = "var(".concat(prefix, "-").concat((0, _lodash3["default"])(key), ")");
        fontStyles[className][key] = varName;
      });
    };

    // Set root styles and generate utility classes
    (0, _lodash["default"])(Object.entries(fontsGroups), function (group) {
      var _group = _slicedToArray(group, 2),
        name = _group[0],
        sizes = _group[1];
      var kebabName = (0, _lodash3["default"])(name);
      var keyName = "".concat(fontPrefix, "-").concat(kebabName);
      (0, _lodash["default"])(Object.entries(sizes), function (spacing) {
        var _spacing = _slicedToArray(spacing, 2),
          bp = _spacing[0],
          fontProps = _spacing[1];
        var props = getFontProps(fontProps);
        if (bp === firstBp) {
          (0, _lodash["default"])(Object.entries(props), function (_ref7) {
            var _ref8 = _slicedToArray(_ref7, 2),
              key = _ref8[0],
              value = _ref8[1];
            var keyNameProp = "".concat(keyName, "-").concat((0, _lodash3["default"])(key));
            rootStyles[':root'][keyNameProp] = value;
          });
          generateFontClasses(props, keyName, name);
          return;
        }
        (0, _lodash["default"])(Object.entries(props), function (_ref9) {
          var _ref10 = _slicedToArray(_ref9, 2),
            key = _ref10[0],
            value = _ref10[1];
          var keyNameProp = "".concat(keyName, "-").concat((0, _lodash3["default"])(key));

          // @ts-ignore
          rootStyles["@screen ".concat(bp)][':root'][keyNameProp] = value;
        });
      });
    });

    // Remove empty root styles
    (0, _lodash["default"])(Object.keys(rootStyles), function (bp) {
      var _rootStyles$bp;
      if (bp !== ':root' && rootStyles[bp][':root'] && Object.keys(rootStyles[bp][':root']).length === 0 && _typeof(rootStyles === null || rootStyles === void 0 || (_rootStyles$bp = rootStyles[bp]) === null || _rootStyles$bp === void 0 ? void 0 : _rootStyles$bp[':root']) === 'object') {
        delete rootStyles[bp];
      }
    });

    // Add definition font variables to root and add utility classes to tailwind config
    addBase(rootStyles);
    addUtilities(fontStyles);
  });
};
var _default = exports["default"] = typographyPlugin;
module.exports = exports.default;