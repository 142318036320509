"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _color = _interopRequireDefault(require("color"));
var _plugin = _interopRequireDefault(require("tailwindcss/plugin.js"));
var _lodash = _interopRequireDefault(require("lodash.get"));
var _lodash2 = _interopRequireDefault(require("lodash.omit"));
var _lodash3 = _interopRequireDefault(require("lodash.foreach"));
var _lodash4 = _interopRequireDefault(require("lodash.mapkeys"));
var _lodash5 = _interopRequireDefault(require("lodash.kebabcase"));
var _deepmerge = _interopRequireDefault(require("deepmerge"));
var _object = require("./utils/object");
var _colors = require("./colors");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; } /**
 * Based on NextUI theme plugin
 * @see https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/plugin.ts
 */
var defaultLayout = {};
var baseStyles = function baseStyles(prefix) {
  return {
    // TODO: Add this as plugin variable, kind defaultForeground and deaultBackground
    color: "hsl(var(--".concat(prefix, "-_foreground))"),
    backgroundColor: "hsl(var(--".concat(prefix, "-_background))")
  };
};
var DEFAULT_PREFIX = 'soluto';
var parsedColorsCache = {};

// @internal
var resolveConfig = function resolveConfig() {
  var themes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultTheme = arguments.length > 1 ? arguments[1] : undefined;
  var prefix = arguments.length > 2 ? arguments[2] : undefined;
  var resolved = {
    variants: [],
    utilities: {},
    colors: {}
  };
  for (var _i = 0, _Object$entries = Object.entries(themes); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      themeName = _Object$entries$_i[0],
      _Object$entries$_i$ = _Object$entries$_i[1],
      extend = _Object$entries$_i$.extend,
      layout = _Object$entries$_i$.layout,
      colors = _Object$entries$_i$.colors;
    var cssSelector = ".theme-".concat(themeName, ",[data-theme=\"").concat(themeName, "\"]");
    // const scheme = themeName === 'light' || themeName === 'dark' ? themeName : extend;

    // if the theme is the default theme, add the selector to the root element
    if (themeName === defaultTheme) {
      cssSelector = ":root,".concat(cssSelector);
    }

    // resolved.utilities[cssSelector] = scheme
    //     ? {
    //           'color-scheme': scheme,
    //       }
    //     : {};

    resolved.utilities[cssSelector] = {};

    // flatten color definitions
    var flatColors = (0, _object.flattenThemeObject)(colors);
    var flatLayout = layout ? (0, _lodash4["default"])(layout, function (value, key) {
      return (0, _lodash5["default"])(key);
    }) : {};

    // resolved.variants
    resolved.variants.push({
      name: themeName,
      definition: ["&.".concat(themeName), "&[data-theme='".concat(themeName, "']")]
    });

    /**
     * Colors
     */
    var _loop = function _loop() {
        var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
          colorName = _Object$entries2$_i[0],
          colorValue = _Object$entries2$_i[1];
        if (!colorValue) return {
          v: void 0
        };
        try {
          var parsedColor = parsedColorsCache[colorValue] || (0, _color["default"])(colorValue).hsl().round(2).array();
          parsedColorsCache[colorValue] = parsedColor;
          var _parsedColor = _slicedToArray(parsedColor, 4),
            h = _parsedColor[0],
            s = _parsedColor[1],
            l = _parsedColor[2],
            defaultAlphaValue = _parsedColor[3];
          var colorVariable = "--".concat(prefix, "-").concat(colorName);
          var colorOpacityVariable = "--".concat(prefix, "-").concat(colorName, "-op");

          // set the css variable in "@layer utilities"
          resolved.utilities[cssSelector][colorVariable] = "".concat(h, " ").concat(s, "% ").concat(l, "%");
          // if an alpha value was provided in the color definition, store it in a css variable
          if (typeof defaultAlphaValue === 'number') {
            resolved.utilities[cssSelector][colorOpacityVariable] = defaultAlphaValue.toFixed(2);
          }
          // set the dynamic color in tailwind config theme.colors
          resolved.colors[colorName] = function (_ref) {
            var opacityVariable = _ref.opacityVariable,
              opacityValue = _ref.opacityValue;
            // if the opacity is set  with a slash (e.g. bg-primary/90), use the provided value
            if (!isNaN(+opacityValue)) {
              return "hsl(var(".concat(colorVariable, ") / ").concat(opacityValue, ")");
            }
            // if no opacityValue was provided (=it is not parsable to a number)
            // the colorOpacityVariable (opacity defined in the color definition rgb(0, 0, 0, 0.5)) should have the priority
            // over the tw class based opacity(e.g. "bg-opacity-90")
            // This is how tailwind behaves as for v3.2.4
            if (opacityVariable) {
              return "hsl(var(".concat(colorVariable, ") / var(").concat(colorOpacityVariable, ", var(").concat(opacityVariable, ")))");
            }
            return "hsl(var(".concat(colorVariable, ") / var(").concat(colorOpacityVariable, ", 1))");
          };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error', error === null || error === void 0 ? void 0 : error.message);
        }
      },
      _ret;
    for (var _i2 = 0, _Object$entries2 = Object.entries(flatColors); _i2 < _Object$entries2.length; _i2++) {
      _ret = _loop();
      if (_ret) return _ret.v;
    }

    /**
     * Layout
     */
    for (var _i3 = 0, _Object$entries3 = Object.entries(flatLayout); _i3 < _Object$entries3.length; _i3++) {
      var _Object$entries3$_i = _slicedToArray(_Object$entries3[_i3], 2),
        key = _Object$entries3$_i[0],
        value = _Object$entries3$_i[1];
      if (!value) return;
      var layoutVariablePrefix = "--".concat(prefix, "-").concat(key);
      if (_typeof(value) === 'object') {
        for (var _i4 = 0, _Object$entries4 = Object.entries(value); _i4 < _Object$entries4.length; _i4++) {
          var _Object$entries4$_i = _slicedToArray(_Object$entries4[_i4], 2),
            nestedKey = _Object$entries4$_i[0],
            nestedValue = _Object$entries4$_i[1];
          var nestedLayoutVariable = "".concat(layoutVariablePrefix, "-").concat(nestedKey);
          resolved.utilities[cssSelector][nestedLayoutVariable] = nestedValue;
        }
      }
    }
  }
  return resolved;
};
var corePlugin = function corePlugin() {
  var themes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultTheme = arguments.length > 1 ? arguments[1] : undefined;
  var prefix = arguments.length > 2 ? arguments[2] : undefined;
  var resolved = resolveConfig(themes, defaultTheme, prefix);
  return (0, _plugin["default"])(function (_ref2) {
    var addBase = _ref2.addBase,
      addUtilities = _ref2.addUtilities,
      addVariant = _ref2.addVariant;
    // add base classNames
    addBase(_defineProperty({}, "[data-theme],".concat(Object.keys(themes).map(function (name) {
      return ".theme-".concat(name);
    }).join(',')), _objectSpread({}, baseStyles(prefix))));

    // add the css variables to "@layer utilities"
    addUtilities(_objectSpread({}, resolved === null || resolved === void 0 ? void 0 : resolved.utilities));
    // add the theme as variant e.g. "[theme-name]:text-2xl"
    resolved === null || resolved === void 0 || resolved.variants.forEach(function (variant) {
      addVariant(variant.name, variant.definition);
    });
  },
  // extend the colors config
  {
    theme: {
      extend: {
        // @ts-ignore
        colors: _objectSpread({}, resolved === null || resolved === void 0 ? void 0 : resolved.colors)
      }
    }
  });
};
var themePlugin = function themePlugin(config) {
  var _config$themes = config.themes,
    themeObject = _config$themes === void 0 ? {} : _config$themes,
    _config$defaultTheme = config.defaultTheme,
    defaultTheme = _config$defaultTheme === void 0 ? 'light' : _config$defaultTheme,
    userLayout = config.layout,
    _config$prefix = config.prefix,
    defaultPrefix = _config$prefix === void 0 ? DEFAULT_PREFIX : _config$prefix,
    _config$colors = config.colors,
    userCommonColors = _config$colors === void 0 ? {} : _config$colors;
  var userLightColors = (0, _deepmerge["default"])((0, _lodash["default"])(themeObject, 'light.colors', {}), userCommonColors);
  var userDarkColors = (0, _deepmerge["default"])((0, _lodash["default"])(themeObject, 'dark.colors', {}), userCommonColors);
  var defaultLayoutObj = userLayout && _typeof(userLayout) === 'object' ? (0, _deepmerge["default"])(defaultLayout, userLayout) : defaultLayout;
  var baseLayouts = {
    light: _objectSpread(_objectSpread({}, defaultLayoutObj), defaultLayout),
    dark: _objectSpread(_objectSpread({}, defaultLayoutObj), defaultLayout)
  };

  // get other themes from the config different from light and dark
  var otherThemes = (0, _lodash2["default"])(themeObject, ['light', 'dark']) || {};
  (0, _lodash3["default"])(otherThemes, function (_ref3, themeName) {
    var extend = _ref3.extend,
      layout = _ref3.layout;
    if (layout && _typeof(layout) === 'object') {
      otherThemes[themeName].layout = (0, _deepmerge["default"])(extend ? baseLayouts[extend] : defaultLayoutObj, layout);
    }
  });
  var light = {
    layout: (0, _deepmerge["default"])(baseLayouts.light, (0, _lodash["default"])(themeObject, 'light.layout', {})),
    colors: (0, _deepmerge["default"])(_colors.semanticColors.light, userLightColors)
  };
  var dark = {
    layout: (0, _deepmerge["default"])(baseLayouts.dark, (0, _lodash["default"])(themeObject, 'dark.layout', {})),
    colors: (0, _deepmerge["default"])(_colors.semanticColors.dark, userDarkColors)
  };
  var themes = _objectSpread({
    light: light,
    dark: dark
  }, otherThemes);
  return corePlugin(themes, defaultTheme, defaultPrefix);
};
var _default = exports["default"] = themePlugin;
module.exports = exports.default;