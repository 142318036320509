"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResponsiveValues = getResponsiveValues;
exports.getStatusClassName = getStatusClassName;
exports.isBreakpoint = void 0;
var _variables = _interopRequireDefault(require("@soluto-design/styles/variables"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// TODO: Maybe this has to be part of SCSS package?

function getBreakpointValues(variables) {
  var BREAKPOINT_UP = {};
  var BREAKPOINT_DOWN = {};
  var orders = [];
  Object.keys(variables).filter(function (key) {
    return key.startsWith('layout-breakpoint-');
  }).forEach(function (key) {
    var breakpoint = key.replace('layout-breakpoint-', '');
    var value = parseInt("".concat(variables[key]));
    BREAKPOINT_UP[breakpoint] = value;
    orders.push([breakpoint, value]);
  });
  orders.forEach(function (_ref, i) {
    var _ref2 = _slicedToArray(_ref, 1),
      breakpoint = _ref2[0];
    if (orders[i + 1]) {
      BREAKPOINT_DOWN[breakpoint] = orders[i + 1][1];
    }
  });
  return {
    BREAKPOINT_UP: BREAKPOINT_UP,
    BREAKPOINT_DOWN: BREAKPOINT_DOWN
  };
}
var _getBreakpointValues = getBreakpointValues(_variables["default"]),
  BREAKPOINT_UP = _getBreakpointValues.BREAKPOINT_UP,
  BREAKPOINT_DOWN = _getBreakpointValues.BREAKPOINT_DOWN;
var isBreakpoint = exports.isBreakpoint = {
  up: function up(bp) {
    return typeof window === 'undefined' || window.innerWidth >= BREAKPOINT_UP[bp];
  },
  down: function down(bp) {
    return typeof window === 'undefined' || BREAKPOINT_DOWN[bp] && window.innerWidth < BREAKPOINT_DOWN[bp];
  },
  between: function between(bp1, bp2) {
    return typeof window === 'undefined' || this.up(bp1) && this.down(bp2);
  },
  only: function only(bp) {
    return typeof window === 'undefined' || this.up(bp) && this.down(bp);
  }
};
function getResponsiveValues(prefix, props) {
  var stylesObject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  switch (_typeof(props)) {
    case 'object':
      return Object.keys(props).reduce(function (css, key) {
        if (props[key] || props[key] === 0) {
          var value = "".concat(prefix, "-").concat(key, "-").concat(props[key]);
          css.push(stylesObject[value] || value);
        }
        return css;
      }, []).join(' ');
    case 'undefined':
      return '';
    default:
      return "".concat(prefix, "-").concat(props);
  }
}
function getStatusClassName(statusClasses, success, error) {
  if (!statusClasses) {
    return '';
  }
  return typeof statusClasses === 'string' ? statusClasses : [statusClasses === null || statusClasses === void 0 ? void 0 : statusClasses["default"], !!error && (statusClasses === null || statusClasses === void 0 ? void 0 : statusClasses.error), !!success && (statusClasses === null || statusClasses === void 0 ? void 0 : statusClasses.success)].join(' ');
}