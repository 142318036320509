import React, {
    ReactNode,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import Picture from '@soluto-design/picture';
import { Button, Text } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';
import { MediaProps } from '../../molecules/Media';
import { PictureProps } from '@soluto-design/picture';

export interface VideoWrapperProps {
    children: ReactNode;
    onClickStart: (event: any) => void;
    className?: string;
    title?: string;
    poster?: PictureProps;
    showPlayButton?: boolean;
    showTitle?: boolean;
    startOnClick?: boolean;
    layout?: MediaProps['layout'];
}

export interface VideoWrapperRef {
    setPlayed: (played: boolean) => void;
}

const VideoWrapper = forwardRef<VideoWrapperRef, VideoWrapperProps>(
    function VideoWrapper(
        {
            children,
            onClickStart,
            poster,
            className,
            title,
            showPlayButton,
            showTitle,
            layout,
            startOnClick,
        },
        ref,
    ) {
        const [played, setPlayed] = useState(false);
        useImperativeHandle(ref, () => ({
            setPlayed,
        }));

        return (
            <div
                data-played={played}
                {...(startOnClick &&
                    !played && { role: 'button', onClick: onClickStart })}
                className={cls(
                    layout === 'fill'
                        ? 'absolute inset-0 w-full h-full'
                        : 'relative',
                    showPlayButton &&
                        cls(
                            'after:bg-background-invert after:absolute after:inset-0 after:transition-opacity',
                            played
                                ? 'after:opacity-0 after:pointer-events-none'
                                : 'after:opacity-50',
                        ),
                    className,
                )}>
                {children}
                {!!poster?.src && (
                    <Picture
                        src={poster.src}
                        alt={title}
                        layout="fill"
                        priority={true}
                        classes={{
                            wrapper: cls('inset-0', played && 'hidden'),
                        }}
                    />
                )}
                {showPlayButton && (
                    <div
                        className={cls(
                            'absolute flex items-center justify-start gap-gutter z-10 transition-opacity',
                            played && 'opacity-0 pointer-events-none',
                            showTitle && !!title
                                ? 'bottom-24 inset-x-24 md:bottom-36 md:inset-x-36 lg:bottom-40 lg:inset-x-40 xl:bottom-64 xl:inset-x-64'
                                : 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                        )}>
                        <Button
                            data-dark
                            component="button"
                            variant="primary"
                            size="icon_extra_large"
                            leftIcon="play--filled-on"
                            className="hidden md:flex"
                            onClick={onClickStart}
                        />
                        <Button
                            data-dark
                            component="button"
                            variant="primary"
                            size="icon_large"
                            leftIcon="play--filled-on"
                            className="md:hidden"
                            onClick={onClickStart}
                        />
                        {showTitle && !!title && (
                            <Text
                                component="span"
                                className="text-text-invert text-h400 md:text-h300 text-balance flex-1 md:max-w-[416px]">
                                {title}
                            </Text>
                        )}
                    </div>
                )}
            </div>
        );
    },
);

export default VideoWrapper;
