"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var baseStyles = {
  backdrop: 'fixed inset-0 transition-opacity opacity-0 z-[199] [&.modal-show]:opacity-100',
  show: 'modal-show',
  modal: 'fixed inset-0 z-[200] hidden overflow-x-hidden overflow-y-visible outline-none opacity-0 transition-opacity [&.modal-show]:opacity-100 [&.modal-show>.modal-dialog]:transform-none',
  dialog: 'modal-dialog flex min-h-full items-center relative w-auto pointer-events-none transition-transform max-w-full',
  content: 'modal-content relative w-full pointer-events-auto outline-none'
};
var dynamicStyles = {
  get: function get(target, prop) {
    if (prop.startsWith('modal-') || prop.startsWith('backdrop-')) {
      return prop.replace('modal-', 'variant-modal-');
    }
    return target[prop] || '';
  }
};
var styles = new Proxy(baseStyles, dynamicStyles);
var _default = exports["default"] = styles;
module.exports = exports.default;