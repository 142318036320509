"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setupViewport;
var remainingSetupTimes = 20;
function setup() {
  var vh = window.innerHeight * 0.01;
  var vw = document.body.clientWidth * 0.01;
  if (vw === 0 && remainingSetupTimes > 0) {
    setTimeout(setup, 200);
  } else {
    var sw = window.innerWidth - document.body.clientWidth;
    document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
    document.documentElement.style.setProperty('--vw', "".concat(vw, "px"));
    document.documentElement.style.setProperty('--sw', "".concat(sw, "px"));
  }
  remainingSetupTimes--;
}
var lastScroll = 0;
function onScroll() {
  window.document.body.dataset.scroll = window.scrollY < 10 ? 'none' : window.scrollY > lastScroll ? 'down' : 'up';
  lastScroll = window.scrollY;
}
function setupViewport() {
  if (typeof window === 'undefined') {
    return function () {};
  }
  window.addEventListener('load', setup);
  window.addEventListener('load', onScroll);
  window.addEventListener('scroll', onScroll);
  window.addEventListener('resize', setup);
  window.addEventListener('orientationchange', setup);
  return function () {
    window.removeEventListener('load', setup);
    window.removeEventListener('load', onScroll);
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', setup);
    window.removeEventListener('orientationchange', setup);
  };
}
module.exports = exports.default;