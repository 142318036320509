import React, { useState, createContext, useRef, useCallback } from 'react';

import ModalVideo, {
    ModalVideoRef,
    ModalVideoProps,
} from 'design-system/src/components/organisms/ModalVideo';

type ModalContextType = {
    isModalOpen: boolean;
    openModal: (autoplay: boolean, props: any) => void;
    closeModal: () => void;
};

const ModalContext = createContext({} as ModalContextType);

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProps, setModalProps] = useState<Partial<ModalVideoProps>>({});

    const modalVideoRef = useRef<ModalVideoRef>(null);

    const openModal = (autoplay: boolean, props: Partial<ModalVideoProps>) => {
        setModalProps({
            autoPlay: autoplay,
            ...props,
        });

        setIsModalOpen(true);

        setTimeout(() => {
            modalVideoRef?.current?.open();
        }, 150);
    };

    const onCloseModal = useCallback(() => {
        setTimeout(() => {
            setIsModalOpen(false);
        }, 150);
    }, []);

    const closeModal = () => {
        modalVideoRef?.current?.close();
    };

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
            }}>
            {children}
            {isModalOpen && (
                <ModalVideo
                    ref={modalVideoRef}
                    {...modalProps}
                    onCloseModal={onCloseModal}
                />
            )}
        </ModalContext.Provider>
    );
};

const ModalConsumer = ModalContext.Consumer;

export { ModalContext, ModalProvider, ModalConsumer };
