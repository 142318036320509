"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.solutoUIConfig = solutoUIConfig;
var _lodash = _interopRequireDefault(require("lodash.merge"));
var _path = _interopRequireDefault(require("path"));
var _theme = _interopRequireDefault(require("./theme"));
var _spacers = _interopRequireDefault(require("./spacers"));
var _typography = _interopRequireDefault(require("./typography"));
var _radius = _interopRequireDefault(require("./radius"));
var _solutoTemplate = _interopRequireDefault(require("./figma/soluto-template.json"));
var _solutoTemplateConfig = _interopRequireDefault(require("./figma/soluto-template.config.json"));
var _figmaConversor2 = _interopRequireDefault(require("./figma/figma-conversor"));
var _excluded = ["darkMode", "plugins", "solutoTheme", "theme", "future", "content", "safelist"];
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function solutoUIConfig(config) {
  var _config$darkMode = config.darkMode,
    darkMode = _config$darkMode === void 0 ? 'class' : _config$darkMode,
    _config$plugins = config.plugins,
    plugins = _config$plugins === void 0 ? [] : _config$plugins,
    _config$solutoTheme = config.solutoTheme,
    solutoTheme = _config$solutoTheme === void 0 ? {} : _config$solutoTheme,
    _config$theme = config.theme,
    theme = _config$theme === void 0 ? {} : _config$theme,
    _config$future = config.future,
    future = _config$future === void 0 ? {} : _config$future,
    _config$content = config.content,
    content = _config$content === void 0 ? [] : _config$content,
    _config$safelist = config.safelist,
    safelist = _config$safelist === void 0 ? [] : _config$safelist,
    tailwindConfig = _objectWithoutProperties(config, _excluded);
  var _ref = solutoTheme || {},
    _ref$prefix = _ref.prefix,
    prefix = _ref$prefix === void 0 ? 'soluto' : _ref$prefix,
    _ref$themes = _ref.themes,
    themes = _ref$themes === void 0 ? {} : _ref$themes,
    _ref$spacers = _ref.spacers,
    spacers = _ref$spacers === void 0 ? {} : _ref$spacers,
    _ref$typography = _ref.typography,
    typography = _ref$typography === void 0 ? {} : _ref$typography,
    _ref$spacersGroupProp = _ref.spacersGroupProperties,
    spacersGroupProperties = _ref$spacersGroupProp === void 0 ? {} : _ref$spacersGroupProp,
    _ref$borderRadius = _ref.borderRadius,
    borderRadius = _ref$borderRadius === void 0 ? {} : _ref$borderRadius,
    _ref$figmaJSON = _ref.figmaJSON,
    figmaJSON = _ref$figmaJSON === void 0 ? _solutoTemplate["default"] : _ref$figmaJSON,
    _ref$figmaConfig = _ref.figmaConfig,
    figmaConfig = _ref$figmaConfig === void 0 ? _solutoTemplateConfig["default"] : _ref$figmaConfig;
  var _figmaConversor = (0, _figmaConversor2["default"])(figmaJSON, figmaConfig, prefix),
    figmaSafelist = _figmaConversor.safelist,
    figmaTheme = _figmaConversor.theme,
    figmaSolutoTheme = _figmaConversor.solutoTheme,
    figmaPlugins = _figmaConversor.plugins,
    variables = _figmaConversor.variables;
  var thisThemes = (0, _lodash["default"])(figmaSolutoTheme.themes, themes);
  return _objectSpread(_objectSpread({}, tailwindConfig), {}, {
    content: [_path["default"].join(__dirname, 'components/**/*.js')].concat(_toConsumableArray(content)),
    safelist: [].concat(_toConsumableArray(figmaSafelist), _toConsumableArray(safelist)),
    future: _objectSpread({
      hoverOnlyWhenSupported: true
    }, future),
    darkMode: darkMode,
    variables: variables,
    theme: _objectSpread(_objectSpread(_objectSpread({}, figmaTheme), theme), {}, {
      extend: _objectSpread({}, (0, _lodash["default"])(theme.extend || {}, {
        transitionProperty: {
          header: 'transform, background-color, border-color, height, box-shadow',
          'header-icon': 'height, color',
          padding: 'padding'
        }
      })),
      screens: _objectSpread(_objectSpread({}, figmaTheme.screens), !!(theme !== null && theme !== void 0 && theme.screens) && theme.screens),
      colors: (0, _lodash["default"])(figmaTheme.colors, theme.colors || {}, {
        transparent: 'transparent'
      }),
      spacing: _objectSpread(_objectSpread({}, figmaTheme.spacing), !!(theme !== null && theme !== void 0 && theme.spacing) && theme.spacing),
      fontFamily: _objectSpread(_objectSpread({}, figmaTheme.fontFamily), !!(theme !== null && theme !== void 0 && theme.fontFamily) && theme.fontFamily),
      fontWeight: _objectSpread(_objectSpread({}, figmaTheme.fontWeight), !!(theme !== null && theme !== void 0 && theme.fontWeight) && theme.fontWeight),
      borderWidth: _objectSpread(_objectSpread({}, figmaTheme.borderWidth), !!(theme !== null && theme !== void 0 && theme.borderWidth) && theme.borderWidth),
      boxShadow: _objectSpread(_objectSpread({}, figmaTheme.boxShadow), !!(theme !== null && theme !== void 0 && theme.boxShadow) && theme.boxShadow),
      outlineWidth: _objectSpread(_objectSpread({}, figmaTheme.outlineWidth), !!(theme !== null && theme !== void 0 && theme.outlineWidth) && theme.outlineWidth)
    }),
    plugins: [(0, _theme["default"])(_objectSpread({
      prefix: prefix
    }, thisThemes)), (0, _spacers["default"])({
      prefix: prefix,
      spacers: (0, _lodash["default"])(figmaSolutoTheme.spacers, spacers),
      spacersGroupProperties: spacersGroupProperties
    }), (0, _typography["default"])({
      prefix: prefix,
      typography: (0, _lodash["default"])(figmaSolutoTheme.typography, typography)
    }), (0, _radius["default"])({
      prefix: prefix,
      borderRadius: (0, _lodash["default"])(figmaSolutoTheme.borderRadius, borderRadius)
    })].concat(_toConsumableArray(figmaPlugins), _toConsumableArray(plugins))
  });
}
var _default = exports["default"] = solutoUIConfig;