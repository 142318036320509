"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = cls;
function cls() {
  for (var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++) {
    classes[_key] = arguments[_key];
  }
  return classes.join(' ');
}
module.exports = exports.default;