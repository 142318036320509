"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var baseStyles = {
  header: 'transition-header flex items-center',
  wrapper: 'header-wrapper transition-padding flex justify-between items-center flex-1 h-full',
  children: 'flex items-center justify-end',
  icon: 'header-icon transition-header-icon',
  sticky: 'header-sticky fixed left-0 w-full z-[100]',
  'sticky-top': 'header-sticky-top top-0',
  'sticky-bottom': 'header-sticky-bottom bottom-0',
  'hide-on-scroll': 'header-hide-on-scroll'
};
var dynamicStyles = {
  get: function get(target, prop) {
    if (prop.startsWith('variant-')) {
      return prop.replace('variant-', 'variant-header-');
    }
    return target[prop] || '';
  }
};
var styles = new Proxy(baseStyles, dynamicStyles);
var _default = exports["default"] = styles;
module.exports = exports.default;