import React from 'react';

import 'design-system/src/components/organisms/HeroHomeSection/client-library';
import '@soluto-design/styles/base';

import { ModalProvider } from './src/context/ModalContext';

export const wrapRootElement = ({ element }) => {
    return (
        <ModalProvider>
            {element}
        </ModalProvider>
    );
};


export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const currentPosition = getSavedScrollPosition(location);

    setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);

    return false;
};