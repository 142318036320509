import React, { useCallback, useRef } from 'react';
import YouTube, { YouTubePlayer, YouTubeEvent } from 'react-youtube';
import { PictureProps } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import { MediaProps } from '../../molecules/Media';
import VideoWrapper, {
    VideoWrapperProps,
    VideoWrapperRef,
} from '../VideoWrapper';

enum ExternalVideoProvidersEnum {
    Youtube = 'youtube',
}

export interface ExternalVideoProps {
    width?: number;
    height?: number;
    poster?: Partial<PictureProps>;
    providerUid: string;
    provider: string;
    title: string;
    layout?: MediaProps['layout'];
    className?: VideoWrapperProps['className'];
    showTitle?: boolean;
    startOnClick?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    onLoaded?: (player: YouTubePlayer) => void;
    onEnd?: () => void;
}

export default function ExternalVideo({
    width,
    height,
    poster,
    providerUid,
    provider,
    title,
    layout = 'responsive',
    className,
    showTitle,
    startOnClick,
    muted = false,
    onLoaded,
    onEnd,
}: ExternalVideoProps) {
    const videoRef = useRef<YouTubePlayer>(null);
    const wrapperRef = useRef<VideoWrapperRef>(null);

    const playVideo = useCallback(() => {
        if (!videoRef?.current) return;

        videoRef.current.playVideo();
    }, []);

    const onVideoPlay = useCallback(() => {
        wrapperRef.current?.setPlayed(true);
    }, [wrapperRef]);

    const onReady = useCallback(
        (event: YouTubeEvent) => {
            videoRef.current = event.target;

            typeof onLoaded === 'function' && onLoaded(event.target);
        },
        [onLoaded],
    );

    return (
        <VideoWrapper
            ref={wrapperRef}
            layout={layout}
            onClickStart={playVideo}
            {...(!!poster?.src && {
                showPlayButton: true,
                showTitle,
                startOnClick,
            })}
            title={title}
            poster={poster}
            className={className}>
            {provider === ExternalVideoProvidersEnum.Youtube && (
                <YouTube
                    {...(!!title && { title })}
                    {...(layout === 'responsive' &&
                        height &&
                        width && {
                            style: { paddingTop: `${(height / width) * 100}%` },
                        })}
                    className={cls(
                        layout === 'responsive'
                            ? 'relative w-full'
                            : 'absolute inset-0 w-full h-full',
                    )}
                    iframeClassName="absolute inset-0 w-full h-full"
                    videoId={providerUid}
                    onReady={onReady}
                    onPlay={onVideoPlay}
                    onEnd={onEnd}
                    opts={{
                        playerVars: {
                            color: 'white',
                            rel: '0',
                            showinfo: '0',
                            mute: muted ? 1 : 0,
                        },
                    }}
                />
            )}
        </VideoWrapper>
    );
}
