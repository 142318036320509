"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = figmaConversor;
var _lodash = _interopRequireDefault(require("lodash.setwith"));
var _lodash2 = _interopRequireDefault(require("lodash.get"));
var _plugin = _interopRequireDefault(require("tailwindcss/plugin"));
var _lodash3 = _interopRequireDefault(require("lodash.kebabcase"));
var _lodash4 = _interopRequireDefault(require("lodash.reduce"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function slugify(str) {
  return str.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '');
}
var getPropsByType = function getPropsByType(type, value) {
  var entries = Object.entries(value);
  return entries.filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      key = _ref2[0];
    return key.includes(type);
  }).reduce(function (acc, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    var formattedKey = key.split('-').filter(function (item) {
      return item !== type;
    }).pop();
    acc.push(formattedKey !== null && formattedKey !== void 0 ? formattedKey : '');
    return acc;
  }, []).filter(Boolean);
};
function figmaConversor(figmaJSON, figmaConfig, prefix) {
  var _figmaConfig$styles, _figmaConfig$styles2, _figmaConfig$styles3, _figmaConfig$layout, _figmaConfig$layout2, _figmaConfig$project, _figmaConfig$componen, _figmaConfig$componen2, _figmaConfig$componen3, _figmaConfig$componen4, _figmaConfig$componen5, _figmaConfig$componen6, _figmaConfig$componen7, _collections$styles, _collections$typograp, _collections$effects, _collections$breakpoi, _reduce, _themes$themes;
  var colors = {};
  var themes = {};
  var spacing = {};
  var spacers = {};
  var typography = {};
  var outline = (figmaConfig === null || figmaConfig === void 0 || (_figmaConfig$styles = figmaConfig.styles) === null || _figmaConfig$styles === void 0 ? void 0 : _figmaConfig$styles.outline) || {};
  var outlineWidth = outline.width ? {
    '0': 0,
    DEFAULT: outline.width
  } : {};
  var fontFamily = (figmaConfig === null || figmaConfig === void 0 || (_figmaConfig$styles2 = figmaConfig.styles) === null || _figmaConfig$styles2 === void 0 || (_figmaConfig$styles2 = _figmaConfig$styles2.typography) === null || _figmaConfig$styles2 === void 0 ? void 0 : _figmaConfig$styles2['font-family']) || {};
  var fontWeight = (figmaConfig === null || figmaConfig === void 0 || (_figmaConfig$styles3 = figmaConfig.styles) === null || _figmaConfig$styles3 === void 0 || (_figmaConfig$styles3 = _figmaConfig$styles3.typography) === null || _figmaConfig$styles3 === void 0 ? void 0 : _figmaConfig$styles3['font-weight']) || {};
  var borderWidth = {};
  var borderRadius = {};
  var boxShadow = {};
  var screens = (figmaConfig === null || figmaConfig === void 0 || (_figmaConfig$layout = figmaConfig.layout) === null || _figmaConfig$layout === void 0 ? void 0 : _figmaConfig$layout.breakpoints) || {};
  var componentsClasses = {};
  var layoutClasses = {
    container: "w-full ".concat(figmaConfig !== null && figmaConfig !== void 0 && (_figmaConfig$layout2 = figmaConfig.layout) !== null && _figmaConfig$layout2 !== void 0 && _figmaConfig$layout2['max-width'] ? "max-w-[".concat(figmaConfig.layout['max-width'], "] mx-auto") : '', " px-margin"),
    row: "grid gap-x-gutter"
  };
  var defaultProjectSettings = figmaConfig['project-settings']["default"];
  var inlineLinkProjectSettings = (_figmaConfig$project = figmaConfig['project-settings']) === null || _figmaConfig$project === void 0 ? void 0 : _figmaConfig$project['inline-link'];
  var buttonClasses = (_figmaConfig$componen = figmaConfig.components) === null || _figmaConfig$componen === void 0 || (_figmaConfig$componen = _figmaConfig$componen.atoms) === null || _figmaConfig$componen === void 0 ? void 0 : _figmaConfig$componen.buttons;
  var tagClasses = (_figmaConfig$componen2 = figmaConfig.components) === null || _figmaConfig$componen2 === void 0 || (_figmaConfig$componen2 = _figmaConfig$componen2.atoms) === null || _figmaConfig$componen2 === void 0 ? void 0 : _figmaConfig$componen2.tags;
  var textFieldsValues = (_figmaConfig$componen3 = figmaConfig.components) === null || _figmaConfig$componen3 === void 0 || (_figmaConfig$componen3 = _figmaConfig$componen3.atoms) === null || _figmaConfig$componen3 === void 0 ? void 0 : _figmaConfig$componen3['text-fields'];
  var selectValues = (_figmaConfig$componen4 = figmaConfig.components) === null || _figmaConfig$componen4 === void 0 || (_figmaConfig$componen4 = _figmaConfig$componen4.atoms) === null || _figmaConfig$componen4 === void 0 ? void 0 : _figmaConfig$componen4.selects;
  var messageValues = (_figmaConfig$componen5 = figmaConfig.components) === null || _figmaConfig$componen5 === void 0 || (_figmaConfig$componen5 = _figmaConfig$componen5.atoms) === null || _figmaConfig$componen5 === void 0 ? void 0 : _figmaConfig$componen5.messages;
  var headerVariants = ((_figmaConfig$componen6 = figmaConfig.components) === null || _figmaConfig$componen6 === void 0 || (_figmaConfig$componen6 = _figmaConfig$componen6.organisms) === null || _figmaConfig$componen6 === void 0 ? void 0 : _figmaConfig$componen6.header) || {};
  var modalVariants = ((_figmaConfig$componen7 = figmaConfig.components) === null || _figmaConfig$componen7 === void 0 || (_figmaConfig$componen7 = _figmaConfig$componen7.atoms) === null || _figmaConfig$componen7 === void 0 ? void 0 : _figmaConfig$componen7.modal) || {};
  var safelist = [];
  Object.keys(screens).forEach(function (key) {
    var val = screens[key];
    if (!isNaN(val)) {
      screens[key] = "".concat(val, "px");
    }
  });
  var collections = figmaJSON.collections.reduce(function (acc, collection) {
    var _collection$name;
    var name = (_collection$name = collection.name) === null || _collection$name === void 0 ? void 0 : _collection$name.toLowerCase().replace(/[^a-z0-9]/g, '');
    if (name) {
      acc[name] = collection.modes;
    }
    return acc;
  }, {});
  var plainColors = {};
  if ((_collections$styles = collections.styles) !== null && _collections$styles !== void 0 && (_collections$styles = _collections$styles[0]) !== null && _collections$styles !== void 0 && (_collections$styles = _collections$styles.variables) !== null && _collections$styles !== void 0 && _collections$styles.length) {
    collections.styles[0].variables.forEach(function (variable) {
      plainColors[variable.name] = variable.value;
      var _variable$name$split = variable.name.split('/'),
        _variable$name$split2 = _toArray(_variable$name$split),
        kind = _variable$name$split2[0],
        name = _variable$name$split2.slice(1);
      var path = name.join(".");
      (0, _lodash["default"])(colors, path, variable.value, Object);
    });
  }
  var themesKeys = Object.keys(collections || {}).filter(function (key) {
    return key.startsWith('themes');
  });
  themesKeys.forEach(function (key) {
    var collectionThemes = collections[key];
    collectionThemes.forEach(function (theme) {
      theme.variables.forEach(function (variable) {
        var _variable$value;
        var _variable$name$split3 = variable.name.split('/'),
          _variable$name$split4 = _toArray(_variable$name$split3),
          kind = _variable$name$split4[0],
          name = _variable$name$split4.slice(1);
        var value = variable.isAlias && (_variable$value = variable.value) !== null && _variable$value !== void 0 && _variable$value.name ? plainColors[variable.value.name] ? plainColors[variable.value.name] : null : variable.value;
        if (value) {
          var configName = name.join('/');
          if (defaultProjectSettings['background-color'] === configName) {
            (0, _lodash["default"])(themes, ['themes', theme.name, 'colors', '_background'].join('.'), value, Object);
          }
          if (defaultProjectSettings['font-color'] === configName) {
            (0, _lodash["default"])(themes, ['themes', theme.name, 'colors', '_foreground'].join('.'), value, Object);
          }
          (0, _lodash["default"])(themes, ['themes', theme.name, 'colors'].concat(_toConsumableArray(name)).join('.'), value, Object);
        }
      });
    });
    Object.keys(themes.themes).forEach(function (name) {
      if (themes.defaultTheme) {
        return;
      }
      var background = (0, _lodash2["default"])(themes.themes[name], 'colors._background');
      var foreground = (0, _lodash2["default"])(themes.themes[name], 'colors._foreground');
      if (background && foreground) {
        themes.defaultTheme = name;
      }
    });
  });
  var plainFonts = {};
  if ((_collections$typograp = collections.typography) !== null && _collections$typograp !== void 0 && (_collections$typograp = _collections$typograp[0].variables) !== null && _collections$typograp !== void 0 && _collections$typograp.length) {
    collections.typography[0].variables.forEach(function (variable) {
      var name = variable.name.split('/').pop().split('-')[0];
      var thisFontFamily = slugify(variable.value.fontFamily);
      if (!fontFamily[thisFontFamily]) {
        throw new Error("Font family ".concat(thisFontFamily, " not found in config"));
      }
      var thisFontWeight = slugify(variable.value.fontWeight);
      if (thisFontWeight && !fontWeight[thisFontWeight]) {
        throw new Error("Font weight ".concat(thisFontWeight, " not found in config"));
      }
      plainFonts[name] = _objectSpread({
        fontFamily: fontFamily[thisFontFamily].join(','),
        letterSpacing: variable.value.letterSpacing / 100
      }, thisFontWeight && {
        fontWeight: fontWeight[thisFontWeight]
      });
    });
  }
  var plainBoxShadowColors = {};
  var textFieldsVariants = (textFieldsValues === null || textFieldsValues === void 0 ? void 0 : textFieldsValues.variants) || {};
  var textFieldsSizes = (textFieldsValues === null || textFieldsValues === void 0 ? void 0 : textFieldsValues.sizes) || {};
  var messagesVariants = (messageValues === null || messageValues === void 0 ? void 0 : messageValues.variants) || {};
  var messagesSizes = (messageValues === null || messageValues === void 0 ? void 0 : messageValues.sizes) || {};
  var getClassNames = function getClassNames(valuesVariant, variant) {
    var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'variant';
    var element = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'text-field';
    var keys = Object.keys(valuesVariant);
    var states = ['default', 'hover', 'focus', 'active', 'disabled', 'error', 'success'];
    var clsx = {};
    var stateElement = 'input';
    var getClassesByState = function getClassesByState(state) {
      if (state === 'hover' || state === 'active' || state === 'focus') {
        return [" .".concat(stateElement, ":").concat(state), ["".concat(stateElement), "".concat(stateElement, "-border"), 'select', 'textarea']];
      }
      if (state === 'disabled' || state === 'error' || state === 'success') {
        return [".".concat(state), ['root', 'label', 'select', 'textarea', "".concat(stateElement), "".concat(stateElement, "-border"), 'message']];
      }
    };
    if (type === 'variant') {
      clsx = (0, _lodash4["default"])(states, function (acc, state) {
        if (!valuesVariant[state]) {
          return acc;
        }
        var elementsKeys = Object.keys(valuesVariant[state]);
        if (state === 'default') {
          acc = _objectSpread(_objectSpread({}, acc), (0, _lodash4["default"])(elementsKeys, function (acc, key) {
            var _valuesVariant$state, _valuesVariant$state2;
            if (!(valuesVariant !== null && valuesVariant !== void 0 && (_valuesVariant$state = valuesVariant[state]) !== null && _valuesVariant$state !== void 0 && _valuesVariant$state[key])) {
              return acc;
            }
            var value = valuesVariant === null || valuesVariant === void 0 || (_valuesVariant$state2 = valuesVariant[state]) === null || _valuesVariant$state2 === void 0 || (_valuesVariant$state2 = _valuesVariant$state2[key]) === null || _valuesVariant$state2 === void 0 ? void 0 : _valuesVariant$state2.trim();
            if (key === 'root') {
              acc["".concat(element, ".").concat(type, "-").concat(variant)] = value;
              return acc;
            }
            acc["".concat(element, ".").concat(type, "-").concat(variant, " .").concat(key)] = value;
            return acc;
          }, []));
          return acc;
        }
        var _getClassesByState = getClassesByState(state),
          _getClassesByState2 = _slicedToArray(_getClassesByState, 2),
          selector = _getClassesByState2[0],
          classes = _getClassesByState2[1];
        acc = _objectSpread(_objectSpread({}, acc), (0, _lodash4["default"])(elementsKeys, function (acc, key) {
          var _valuesVariant$state3, _valuesVariant$state$;
          if (!classes.includes(key) || !(valuesVariant !== null && valuesVariant !== void 0 && (_valuesVariant$state3 = valuesVariant[state]) !== null && _valuesVariant$state3 !== void 0 && _valuesVariant$state3[key])) {
            return acc;
          }
          var value = (_valuesVariant$state$ = valuesVariant[state][key]) === null || _valuesVariant$state$ === void 0 ? void 0 : _valuesVariant$state$.trim();
          if (['hover', 'focus', 'active'].includes(state)) {
            acc["".concat(element, ".").concat(type, "-").concat(variant).concat(selector).concat(key === stateElement ? '' : "~ .".concat(key))] = value;
            return acc;
          }
          if (key === 'root') {
            acc["".concat(element, ".").concat(type, "-").concat(variant).concat(selector)] = value;
            return acc;
          }
          acc["".concat(element, ".").concat(type, "-").concat(variant).concat(selector, " .").concat(key)] = value;
          return acc;
        }, []));
        return acc;
      }, {});
      return clsx;
    }
    clsx = keys.reduce(function (acc, key) {
      var _valuesVariant$key;
      if (!(valuesVariant !== null && valuesVariant !== void 0 && valuesVariant[key])) {
        return acc;
      }
      var value = (_valuesVariant$key = valuesVariant[key]) === null || _valuesVariant$key === void 0 ? void 0 : _valuesVariant$key.trim();
      if (key === 'root') {
        acc["".concat(element, ".").concat(type, "-").concat(variant)] = value;
        return acc;
      }
      acc["".concat(element, ".").concat(type, "-").concat(variant, " .").concat(key)] = value;
      return acc;
    }, {});
    return clsx;
  };
  var textFieldsVariantsClasses = Object.keys(textFieldsVariants).reduce(function (acc, current) {
    var values = textFieldsVariants[current];
    acc.push(getClassNames(values, current, 'variant'));
    return acc;
  }, []);
  var textFieldsSizesClasses = Object.keys(textFieldsSizes).reduce(function (acc, current) {
    var values = textFieldsSizes[current];
    acc.push(getClassNames(values, current, 'size'));
    return acc;
  }, []);
  var messagesVariantsClasses = Object.keys(messagesVariants).reduce(function (acc, current) {
    var values = messagesVariants[current];
    if (!(values !== null && values !== void 0 && values.color)) {
      return acc;
    }
    acc.push(_defineProperty({}, "message.variant-".concat(current), values === null || values === void 0 ? void 0 : values.color));
    return acc;
  }, []);
  var messagesSizesClasses = Object.keys(messagesSizes).reduce(function (acc, current) {
    var value = messagesSizes[current];
    if (!value) {
      return acc;
    }
    acc.push(_defineProperty({}, "message.size-".concat(current), value));
    return acc;
  }, []);
  if ((_collections$effects = collections.effects) !== null && _collections$effects !== void 0 && _collections$effects.length) {
    collections.effects.forEach(function (effect) {
      effect.variables.forEach(function (variable) {
        var _variable$value2;
        var _variable$name$split5 = variable.name.split('/'),
          _variable$name$split6 = _slicedToArray(_variable$name$split5, 2),
          kind = _variable$name$split6[0],
          name = _variable$name$split6[1];
        var effect = (_variable$value2 = variable.value) === null || _variable$value2 === void 0 || (_variable$value2 = _variable$value2.effects) === null || _variable$value2 === void 0 ? void 0 : _variable$value2[0];
        if ((effect === null || effect === void 0 ? void 0 : effect.type) === 'DROP_SHADOW') {
          var color = effect.color;
          plainBoxShadowColors[name] = "rgba(".concat(color.r, ", ").concat(color.g, ", ").concat(color.b, ", ").concat(color.a, ")");
        }
      });
    });
  }
  if ((_collections$breakpoi = collections.breakpoints) !== null && _collections$breakpoi !== void 0 && _collections$breakpoi.length) {
    collections.breakpoints.forEach(function (breakpoint, i) {
      breakpoint.variables.forEach(function (variable) {
        var _variable$name$split7 = variable.name.split('/'),
          _variable$name$split8 = _toArray(_variable$name$split7),
          kind = _variable$name$split8[0],
          kind2 = _variable$name$split8[1],
          rest = _variable$name$split8.slice(2);
        if (kind === '(spacers)') {
          if (kind2 === '(static)') {
            spacing[variable.value] = variable.value;
          } else if (kind2 === '(responsive)') {
            (0, _lodash["default"])(spacers, [].concat(_toConsumableArray(rest), [breakpoint.name]).join('.'), variable.value, Object);
          }
        } else if (kind === 'typography') {
          var name = rest.pop();
          var plainFont = plainFonts[name];
          var previous = (0, _lodash2["default"])(typography, [name, breakpoint.name, 1].join('.'));
          if (kind2 === 'size') {
            (0, _lodash["default"])(typography, [name, breakpoint.name, 0].join('.'), variable.value);
            var base = _objectSpread(_objectSpread(_objectSpread({}, !!previous && previous), !i && plainFont ? plainFont : {}), !!(plainFont !== null && plainFont !== void 0 && plainFont.letterSpacing) && {
              letterSpacing: plainFont.letterSpacing * variable.value
            });
            (0, _lodash["default"])(typography, [name, breakpoint.name, 1].join('.'), base);
          } else if (kind2 === 'line-height') {
            (0, _lodash["default"])(typography, [name, breakpoint.name, 1].join('.'), _objectSpread(_objectSpread({}, !!previous && previous), {}, {
              lineHeight: variable.value
            }));
          }
        } else if (kind === 'stroke') {
          borderWidth[kind2] = variable.value;
        } else if (kind === 'border radius') {
          (0, _lodash["default"])(borderRadius, [kind2, breakpoint.name].join('.'), variable.value, Object);
        } else if (kind === 'effects') {
          Object.keys(plainBoxShadowColors).forEach(function (shadowName) {
            (0, _lodash["default"])(boxShadow, [shadowName, 'color'].join('.'), plainBoxShadowColors[shadowName], Object);
            (0, _lodash["default"])(boxShadow, [shadowName, kind2].join('.'), isNaN(variable.value) ? variable.value : "".concat(variable.value, "px"), Object);
          });
        } else if (kind === 'layout') {
          if (kind2 === 'gutter' || kind2 === 'margin') {
            (0, _lodash["default"])(spacers, [kind2, breakpoint.name].join('.'), variable.value, Object);
          } else if (kind2 === 'column') {
            layoutClasses.row += " ".concat([breakpoint.name !== 'xs' && breakpoint.name, "grid-cols-".concat(variable.value)].filter(Boolean).join(':'));
          }
        }
      });
    });
    Object.keys(boxShadow).forEach(function (shadowName) {
      var _boxShadow$shadowName = boxShadow[shadowName],
        x = _boxShadow$shadowName.x,
        y = _boxShadow$shadowName.y,
        blur = _boxShadow$shadowName.blur,
        color = _boxShadow$shadowName.color;
      boxShadow[shadowName] = "".concat(x, " ").concat(y, " ").concat(blur, " 0px ").concat(color);
    });
    boxShadow.none = 'none';
  }
  Object.keys(layoutClasses).forEach(function (key) {
    componentsClasses[".".concat(key)] = _defineProperty({}, "@apply ".concat(layoutClasses[key]), {});
  });
  Object.keys(buttonClasses).filter(function (key) {
    return !!buttonClasses[key];
  }).forEach(function (key) {
    componentsClasses[".".concat(key)] = _defineProperty({}, "@apply ".concat(buttonClasses[key]), {});
    safelist.push(key);
  });
  Object.keys(tagClasses).filter(function (key) {
    return !!tagClasses[key];
  }).forEach(function (key) {
    var className = "variant-tag-".concat(key);
    componentsClasses[".".concat(className)] = _defineProperty({}, "@apply ".concat(tagClasses[key]), {});
    safelist.push(className);
  });
  textFieldsVariantsClasses.forEach(function (classes) {
    Object.keys(classes).forEach(function (key) {
      componentsClasses[".".concat(key)] = _defineProperty({}, "@apply ".concat(classes[key]), {});
      safelist.push(key);
    });
  });
  textFieldsSizesClasses.forEach(function (classes) {
    Object.keys(classes).forEach(function (key) {
      componentsClasses[".".concat(key)] = _defineProperty({}, "@apply ".concat(classes[key]), {});
      safelist.push(key);
    });
  });
  messagesVariantsClasses.forEach(function (classes) {
    Object.keys(classes).forEach(function (key) {
      componentsClasses[".".concat(key)] = _defineProperty({}, "@apply ".concat(classes[key]), {});
      safelist.push(key);
    });
  });
  messagesSizesClasses.forEach(function (classes) {
    Object.keys(classes).forEach(function (key) {
      componentsClasses[".".concat(key)] = _defineProperty({}, "@apply ".concat(classes[key]), {});
      safelist.push(key);
    });
  });
  var messagesInlineIcons = (_reduce = (0, _lodash4["default"])(Object.keys(messagesVariants), function (acc, current) {
    var _messagesVariants$cur, _messagesVariants$cur2;
    if (!(messagesVariants !== null && messagesVariants !== void 0 && (_messagesVariants$cur = messagesVariants[current]) !== null && _messagesVariants$cur !== void 0 && _messagesVariants$cur.icon)) {
      return acc;
    }
    acc["message-inline-".concat(current, "-icon")] = messagesVariants === null || messagesVariants === void 0 || (_messagesVariants$cur2 = messagesVariants[current]) === null || _messagesVariants$cur2 === void 0 ? void 0 : _messagesVariants$cur2.icon;
    return acc;
  }, {})) !== null && _reduce !== void 0 ? _reduce : {};
  var headerBaseClasses = {};
  var headerIcons = {};
  if (Object.keys(headerVariants).length) {
    componentsClasses['body[data-scroll="down"] .header-hide-on-scroll.header-sticky-top'] = {
      '@apply -translate-y-full': {}
    };
    componentsClasses['body[data-scroll="down"] .header-hide-on-scroll.header-sticky-bottom'] = {
      '@apply translate-y-full': {}
    };
    var setupSingleHeader = function setupSingleHeader(selector, headerStyles) {
      if (headerStyles.height || headerStyles.color) {
        componentsClasses[selector] = _defineProperty({}, "@apply ".concat(headerStyles.height || '', " ").concat(headerStyles.color || ''), {});
      }
      if (headerStyles.padding) {
        componentsClasses["".concat(selector, " .header-wrapper")] = _defineProperty({}, "@apply ".concat(headerStyles.padding), {});
      }
      if (headerStyles['icon-color'] || headerStyles['icon-height']) {
        componentsClasses["".concat(selector, " .header-icon")] = _defineProperty({}, "@apply ".concat(headerStyles['icon-color'] || '', " ").concat(headerStyles['icon-height'] || ''), {});
      }
    };
    Object.keys(headerVariants).forEach(function (variant) {
      var className = "variant-header-".concat(variant);
      var headerStyles = headerVariants[variant];
      if (headerStyles.height) {
        headerBaseClasses["[data-header-".concat(variant, "-height]:before, [data-header-").concat(variant, "-child-height] > *:first-child:before")] = _defineProperty({
          content: '""'
        }, "@apply block w-full ".concat(headerStyles.height), {});
      }
      if (headerStyles.icon) {
        headerIcons["header-".concat(variant, "-icon")] = headerStyles.icon;
      }
      setupSingleHeader(".".concat(className), headerStyles);
      safelist.push(className);
      if (headerStyles.sticky) {
        setupSingleHeader("body[data-scroll]:not([data-scroll='none']) .".concat(className, ".header-sticky:not(.header-hide-on-scroll)"), headerStyles.sticky);
      }
    });
  }
  if (Object.keys(modalVariants).length) {
    componentsClasses['.position-x-center'] = {
      '@apply mx-auto': {}
    };
    componentsClasses['.position-x-left'] = {
      '@apply ml-0 mr-auto': {}
    };
    componentsClasses['.position-x-right'] = {
      '@apply ml-auto mr-0': {}
    };
    componentsClasses['.position-y-top'] = {
      '@apply items-start': {}
    };
    componentsClasses['.position-y-center'] = {};
    componentsClasses['.position-y-bottom'] = {
      '@apply items-end': {}
    };
    Object.keys(modalVariants).forEach(function (variant) {
      var className = "variant-modal-".concat(variant);
      var modalStyles = modalVariants[variant];
      if (modalStyles['backdrop-color']) {
        componentsClasses[".backdrop-".concat(variant)] = _defineProperty({}, "@apply ".concat(modalStyles['backdrop-color']), {});
        safelist.push("backdrop-".concat(variant));
      }
      if (modalStyles.height) {
        componentsClasses[".".concat(className)] = _defineProperty({}, "@apply overflow-y-hidden", {});
      }
      if (modalStyles['show-transform'] || modalStyles.width || modalStyles.position) {
        componentsClasses[".".concat(className, " .modal-dialog")] = _defineProperty({}, "@apply ".concat(modalStyles['show-transform'] || '', " ").concat(modalStyles.width || '', " ").concat(modalStyles.position || ''), {});
      }
      if (modalStyles.color || modalStyles.padding || modalStyles.height) {
        componentsClasses[".".concat(className, " .modal-content")] = _defineProperty({}, "@apply ".concat(modalStyles.color || '', " ").concat(modalStyles.padding || '', " ").concat(modalStyles.height ? 'overflow-y-auto' : '', " ").concat(modalStyles.height || ''), {});
      }
      safelist.push(className);
    });
  }
  var thisLayoutBreakpoints = Object.entries(screens !== null && screens !== void 0 ? screens : {}).reduce(function (acc, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      key = _ref6[0],
      value = _ref6[1];
    acc['layout-breakpoint-' + key] = value;
    return acc;
  }, {});
  return {
    safelist: safelist,
    theme: {
      screens: screens,
      colors: colors,
      spacing: spacing,
      fontFamily: fontFamily,
      fontWeight: fontWeight,
      borderWidth: borderWidth,
      boxShadow: boxShadow,
      outlineWidth: outlineWidth
    },
    solutoTheme: {
      themes: themes,
      spacers: spacers,
      typography: typography,
      borderRadius: borderRadius
    },
    plugins: [(0, _plugin["default"])(function (_ref7) {
      var _typography$defaultPr;
      var addBase = _ref7.addBase,
        addComponents = _ref7.addComponents,
        addVariant = _ref7.addVariant,
        addUtilities = _ref7.addUtilities;
      var baseTypography = (_typography$defaultPr = typography[defaultProjectSettings['font-size']]) === null || _typography$defaultPr === void 0 || (_typography$defaultPr = _typography$defaultPr.xs) === null || _typography$defaultPr === void 0 ? void 0 : _typography$defaultPr[1];
      var fontPrefix = "--".concat(prefix, "-font-").concat((0, _lodash3["default"])(defaultProjectSettings['font-size']));
      addBase(_objectSpread(_objectSpread({
        html: {
          '@apply scroll-smooth antialiased': {}
        },
        body: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, !!themes.defaultTheme && {
          color: "hsl(var(--".concat(prefix, "-_foreground))"),
          backgroundColor: "hsl(var(--".concat(prefix, "-_background))")
        }), {}, {
          fontSize: "var(".concat(fontPrefix, "-font-size)")
        }, !!(baseTypography !== null && baseTypography !== void 0 && baseTypography.fontFamily) && {
          fontFamily: "var(".concat(fontPrefix, "-font-family)")
        }), !!(baseTypography !== null && baseTypography !== void 0 && baseTypography.letterSpacing) && {
          letterSpacing: "var(".concat(fontPrefix, "-letter-spacing)")
        }), !!(baseTypography !== null && baseTypography !== void 0 && baseTypography.lineHeight) && {
          lineHeight: "var(".concat(fontPrefix, "-line-height)")
        }), {}, _defineProperty({}, "@apply font-".concat(defaultProjectSettings['font-weight']), {})),
        'a,button,input,select,textarea': _defineProperty({}, "@apply outline-0 focus-visible:outline active:outline-0 disabled:outline-0 outline-offset-".concat(outline.offset, " outline-").concat(outline.color.replaceAll('/', '-')), {})
      }, !!inlineLinkProjectSettings && {
        'a:not([role="button"]):not([data-btn])': _defineProperty({}, "@apply ".concat(inlineLinkProjectSettings), {})
      }), headerBaseClasses));
      addComponents(componentsClasses);
      addVariant('icon', '& svg');
      addVariant('input-icon', '& [data-position]');
      addVariant('input-icon-left', '& [data-position="left"]');
      addVariant('input-icon-right', '& [data-position="right"]');
      addVariant('button-right', "& button[data-position='right']");
      addVariant('button-left', "& button[data-position='left']");
      addVariant('input-with-icon', '&[data-left-icon="true"], &[data-right-icon="true"]');
      addVariant('input-with-icon-left', '&[data-left-icon="true"]');
      addVariant('input-with-icon-right', '&[data-right-icon="true"]');
      addUtilities({
        '.scrollbar-hide': {
          /* IE and Edge */
          '-ms-overflow-style': 'none',
          /* Firefox */
          'scrollbar-width': 'none',
          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        },
        '.scrollbar-default': {
          /* IE and Edge */
          '-ms-overflow-style': 'auto',
          /* Firefox */
          'scrollbar-width': 'auto',
          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'block'
          }
        }
      });
    })],
    variables: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, thisLayoutBreakpoints), headerIcons), messagesInlineIcons), {}, {
      'text-fields-variants': Object.keys(textFieldsVariants),
      'text-fields-sizes': Object.keys(textFieldsSizes),
      'button-variants': getPropsByType('variant', buttonClasses),
      'button-sizes': getPropsByType('size', buttonClasses),
      'tag-variants': Object.keys(tagClasses),
      'tag-sizes': getPropsByType('size', tagClasses),
      elevations: Object.keys(boxShadow),
      radius: Object.keys(borderRadius),
      spacers: Object.keys(spacers),
      typography: Object.keys(typography),
      'theme-default': themes.defaultTheme,
      themes: (_themes$themes = themes === null || themes === void 0 ? void 0 : themes.themes) !== null && _themes$themes !== void 0 ? _themes$themes : {},
      gradients: []
    })
  };
}
module.exports = exports.default;