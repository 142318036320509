"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flattenThemeObject = void 0;
exports.removeDefaultKeys = removeDefaultKeys;
exports.swapColorValues = swapColorValues;
var _flat = _interopRequireDefault(require("flat"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function swapColorValues(colors) {
  var swappedColors = {};
  var keys = Object.keys(colors);
  var length = keys.length;
  for (var i = 0; i < length / 2; i++) {
    var key1 = keys[i];
    var key2 = keys[length - 1 - i];

    // @ts-ignore
    swappedColors[key1] = colors[key2];
    // @ts-ignore
    swappedColors[key2] = colors[key1];
  }
  if (length % 2 !== 0) {
    var middleKey = keys[Math.floor(length / 2)];

    // @ts-ignore
    swappedColors[middleKey] = colors[middleKey];
  }
  return swappedColors;
}
function removeDefaultKeys(obj) {
  var newObj = {};
  for (var key in obj) {
    if (key.endsWith("-DEFAULT")) {
      // @ts-ignore
      newObj[key.replace("-DEFAULT", "")] = obj[key];
      continue;
    }
    // @ts-ignore
    newObj[key] = obj[key];
  }
  return newObj;
}

/**
 *
 * Flatten theme object and remove default keys
 *
 * @param obj theme object
 * @returns object with flattened keys
 */
var flattenThemeObject = exports.flattenThemeObject = function flattenThemeObject(obj) {
  return removeDefaultKeys((0, _flat["default"])(obj, {
    safe: true,
    delimiter: "-"
  }));
};