"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
function createRootElement(id) {
  var rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}
function addRootElement(rootElem) {
  if (typeof document !== 'undefined') {
    var _document;
    document.body.insertBefore(rootElem, (_document = document) === null || _document === void 0 || (_document = _document.body) === null || _document === void 0 || (_document = _document.lastElementChild) === null || _document === void 0 ? void 0 : _document.nextElementSibling);
  }
}
function usePortal(id) {
  var rootElemRef = (0, _react.useRef)();
  (0, _react.useEffect)(function setupElement() {
    if (typeof window !== 'undefined') {
      var existingParent = document.querySelector("#".concat(id));
      var parentElem = existingParent || createRootElement(id);
      if (!existingParent) {
        addRootElement(parentElem);
      }
      parentElem.appendChild(rootElemRef.current);
      return function () {
        var _rootElemRef$current;
        rootElemRef === null || rootElemRef === void 0 || (_rootElemRef$current = rootElemRef.current) === null || _rootElemRef$current === void 0 || _rootElemRef$current.remove();
        if (!parentElem.childElementCount) {
          parentElem.remove();
        }
      };
    }
  }, [id]);
  function getRootElem() {
    if (typeof document !== 'undefined') {
      if (!rootElemRef.current) {
        rootElemRef.current = document.createElement('div');
      }
      return rootElemRef.current;
    }
    return undefined;
  }
  return getRootElem();
}
var _default = exports["default"] = usePortal;
module.exports = exports.default;