"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _plugin = _interopRequireDefault(require("tailwindcss/plugin.js"));
var _lodash = _interopRequireDefault(require("lodash.foreach"));
var _lodash2 = _interopRequireDefault(require("lodash.kebabcase"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var radiusPlugin = function radiusPlugin(_ref) {
  var prefix = _ref.prefix,
    _ref$borderRadius = _ref.borderRadius,
    borderRadius = _ref$borderRadius === void 0 ? {} : _ref$borderRadius;
  return (0, _plugin["default"])(function (_ref2) {
    var addBase = _ref2.addBase,
      addUtilities = _ref2.addUtilities,
      theme = _ref2.theme;
    var radiusGroups = borderRadius;
    var spacePrefix = prefix ? "--".concat(prefix, "-rounded") : "--rounded";
    var cssSpacingProps = {
      rounded: ["border-radius"],
      "rounded-t": ["border-top-left-radius", "border-top-right-radius"],
      "rounded-b": ["border-bottom-left-radius", "border-bottom-right-radius"],
      "rounded-l": ["border-top-left-radius", "border-bottom-left-radius"],
      "rounded-r": ["border-top-right-radius", "border-bottom-right-radius"],
      "rounded-tl": ["border-top-left-radius"],
      "rounded-tr": ["border-top-right-radius"],
      "rounded-bl": ["border-bottom-left-radius"],
      "rounded-br": ["border-bottom-right-radius"],
      "rounded-s": ["border-start-start-radius", "border-start-end-radius"],
      "rounded-e": ["border-end-start-radius", "border-end-end-radius"],
      "rounded-ss": ["border-start-start-radius"],
      "rounded-se": ["border-start-end-radius"],
      "rounded-es": ["border-end-start-radius"],
      "rounded-ee": ["border-end-end-radius"]
    };
    var breakpoints = _objectSpread({
      xs: "0px"
    }, theme("screens"));
    var rootStyles = {
      ":root": {}
    };
    var spacingStyles = {};
    var firstBp = Object.keys(breakpoints).at(0);

    //  Create root styles for each breakpoint
    Object.keys(breakpoints).forEach(function (bp) {
      if (bp !== firstBp) {
        rootStyles["@screen ".concat(bp)] = {
          ":root": {}
        };
      }
    });

    // Function to generate utility classes
    var generateSpacingClasses = function generateSpacingClasses(spacingProp, name) {
      var _spacingProp = _slicedToArray(spacingProp, 2),
        propName = _spacingProp[0],
        cssProps = _spacingProp[1];
      var varName = "var(".concat(spacePrefix, "-").concat(name, ")");
      var className = ".".concat(propName, "-").concat(name);
      spacingStyles[className] = spacingStyles[className] || {};
      (0, _lodash["default"])(cssProps, function (prop) {
        spacingStyles[className][prop] = varName;
      });
    };

    // Set root styles and generate utility classes
    (0, _lodash["default"])(Object.entries(radiusGroups), function (group) {
      var _group = _slicedToArray(group, 2),
        name = _group[0],
        spacings = _group[1];
      var kebabName = (0, _lodash2["default"])(name);
      var keyName = "".concat(spacePrefix, "-").concat(kebabName);
      (0, _lodash["default"])(Object.entries(spacings), function (spacing) {
        var _spacing = _slicedToArray(spacing, 2),
          bp = _spacing[0],
          space = _spacing[1];
        var spaceRem = "".concat(typeof space === "string" ? parseInt(space, 10) / 16 : space / 16, "rem");
        if (bp === firstBp) {
          rootStyles[":root"][keyName] = spaceRem;
          // create utility class
          (0, _lodash["default"])(Object.entries(cssSpacingProps), function (spacing) {
            return generateSpacingClasses(spacing, kebabName);
          });
        } else {
          rootStyles["@screen ".concat(bp)][":root"][keyName] = spaceRem;
        }
      });
    });

    // Remove empty root styles
    (0, _lodash["default"])(Object.keys(rootStyles), function (key) {
      if (key !== ":root" && rootStyles[key][":root"] && Object.keys(rootStyles[key][":root"]).length === 0 && rootStyles[key][":root"].constructor === Object) {
        delete rootStyles[key];
      }
    });

    // Add definition radius variables and utility classes of radius
    addBase(rootStyles);
    addUtilities(spacingStyles);
  });
};
var _default = exports["default"] = radiusPlugin;
module.exports = exports.default;