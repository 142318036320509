"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var baseStyles = {
  wrapper: 'text-field',
  label: 'label block',
  error: 'error',
  success: 'success',
  disabled: 'disabled',
  'input-wrapper': 'input-wrapper relative flex items-center input-icon:absolute input-icon:top-1/2 input-icon:-translate-y-1/2 input-icon:transition-all input-icon:duration-150 input-icon:ease-in-out',
  message: 'message',
  input: 'input flex-1',
  border: 'input-border absolute inset-0 pointer-events-none bg-transparent',
  select: 'select appearance-none w-full',
  textarea: 'textarea'
};
var dynamicStyles = {
  get: function get(target, prop) {
    var _target$prop;
    if (prop.startsWith('variant-') || prop.startsWith('size-')) {
      return prop;
    }
    return (_target$prop = target[prop]) !== null && _target$prop !== void 0 ? _target$prop : '';
  }
};
var styles = new Proxy(baseStyles, dynamicStyles);
var _default = exports["default"] = styles;
module.exports = exports.default;